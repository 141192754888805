@font-face {
   font-family: Yekan;
   src: url(./assets/BYEKAN.TTF);
}
* {
   font-family: Yekan, sans-serif;
   box-sizing: border-box;
   user-select: none;
   margin: 0;
   padding: 0;
}
body {
   min-width: 300px;
   min-height: 100vh;
   background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
      url('assets/walpaper2.jpg');
   background-repeat: no-repeat;
   direction: rtl;
}

#LoadingDiv {
   position: absolute;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 5;
   width: 100%;
   height: 100%;
   color: white;
   background-color: rgba(0, 0, 0, 0.932);
}
#LoadingDiv label {
   position: absolute;
   font-size: 1.2rem;
   left: 50%;
   transform: translate(-50%, 0);
   top: 65%;
}
.Spinner {
   border-radius: 50%;
   width: 10em;
   height: 10em;
   margin: 60px auto;
   border-top: 1.1em solid rgba(99, 99, 99, 0.7);
   border-right: 1.1em solid rgba(99, 99, 99, 0.7);
   border-bottom: 1.1em solid rgba(99, 99, 99, 0.7);
   border-left: 1.1em solid #ffffff;
   -webkit-animation: loading 1.1s infinite linear;
   animation: loading 1.1s infinite linear;
}

@-webkit-keyframes loading {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}
@keyframes loading {
   0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
   }
   100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
   }
}

.SearchBar {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 150px;
   width: 100%;
   border-radius: 5px;
   border: none;
   font-size: 1.5rem;
   text-align: center;
}

.SearchBarInput {
   background-color: #ffffff;
   box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
   width: 55%;
   min-height: 3.5rem;
   padding: 0 1rem;
   border: none;
   border-radius: 0 10px 10px 0;
   font-size: 1.8rem;
   transition: 0.3s;
}
.SearchBarInput::placeholder {
   color: #000;
   opacity: 0.9;
   font-size: 1.4rem;
   transition: 0.3s;
}

.SearchBarButton {
   cursor: pointer;
   height: 3.5rem;
   width: 130px;
   border-radius: 10px 0 0 10px;
   border: none;
   background-color: rgba(0, 0, 0, 0.671);
   color: white;
   box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
   font-size: 1.2rem;
   transition: 0.3s ease-in-out;
}
.SearchBarButton:hover {
   color: white;
   background-color: rgba(0, 0, 0, 0.9);
}

.Main {
   padding: 2rem;
   margin: 0rem auto 3rem auto;
   min-width: 335px;
   width: 40%;
   min-height: 500px;
   box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
   border: 5px solid rgba(255, 255, 255, 1);
   border-radius: 10px;
   color: black;
   background-color: rgba(255, 255, 255, 1);
}

.Main hr {
   opacity: 0.5;
   margin: 3rem 0 1rem 0;
}

.Temp {
   text-align: center;
   margin: 0;
   font-size: 6rem;
   transition: 0.5s;
}

.Temp::after {
   content: ' سانتی گراد ';
   display: contents;
   position: absolute;
   text-align: right;
   font-size: 0.8rem;
}

.Location {
   display: flex;
   align-items: center;
   margin: -1rem 0 2rem 0;
   font-size: 1rem;
   justify-content: space-between;
}
.Location div {
   display: flex;
   gap: 0.5rem;
   align-items: center;
}
.refresh button {
   background-color: transparent;
   border: 0;
   font-size: 1rem;
   cursor: pointer;
   transition: 2s;
}
.refresh button:hover {
   transform: rotate(360deg);
}
.refresh p {
   font-size: 0.8rem;
   opacity: 0.3;
   font-weight: 900;
}

.ForecastDiv {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 0.6rem;
   font-weight: bold;
   gap: 3rem;
   padding: 1rem;
}

.ForecastDiv div {
   text-align: center;
}
.ForecastDiv div label i {
   margin: 0 0.5rem;
}

.FeelsLike {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 1rem 0;
}
.FeelsLike img {
   width: 60px;
   height: 60px;
}
.ForecastDiv label {
   font-weight: bold;
   font-size: 1.2rem;
}

.minmaxDiv {
   display: flex;
   justify-content: center;
   gap: 1rem;
   font-size: 1rem;
   font-weight: bold;
   margin: 0.5rem 0;
}
.minIcon {
   margin: 0 0.5rem;
   color: #0099ff96;
}
.maxIcon {
   margin: 0 0.5rem;
   color: #e3574dbd;
}

.flag {
   border-radius: 100px;
}
